import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Popconfirm, Typography } from "antd";
import dayjs from "dayjs";
import { formatPrice } from "util/price";

import { Table } from "components/Table";
import { TableUserStatus } from "components/TableUserStatus";
import { usePagination } from "hooks/usePagination";

import { TableUser } from "../types";

const Paragraph = Typography.Paragraph;

type Props = {
  loading?: boolean;
  tableUsers: TableUser[];
  deactivateTableUser({ tableUserId, shopId }: { tableUserId: string; shopId: string }): void;
};

export const TableUserTable = memo<Props>(({ loading, tableUsers, deactivateTableUser }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "テーブル名",
      width: 120,
      render(_: string, { table: { name } }: TableUser) {
        return name;
      },
    },
    {
      title: "ID",
      width: 200,
      render(_: unknown, { id, shopId }: TableUser) {
        return (
          <Paragraph copyable={{ text: id }}>
            <Link to={`/table-users/${id}?shopId=${shopId}`}>{id}</Link>
          </Paragraph>
        );
      },
    },
    {
      title: "親の ID",
      width: 200,
      render(_: unknown, { id, billableTableUserId }: TableUser) {
        if (id === billableTableUserId) return null;
        return (
          <Paragraph copyable={{ text: billableTableUserId }}>{billableTableUserId}</Paragraph>
        );
      },
    },
    {
      title: "テーブル立ち上げ日時",
      dataIndex: "activatedAt",
      render(text: string) {
        return <Paragraph>{dayjs(text).format("YYYY/MM/DD HH:mm")}</Paragraph>;
      },
    },
    {
      title: "インアクティブ日時",
      dataIndex: "deactivatedAt",
      render(text: string) {
        return <Paragraph>{text && dayjs(text).format("YYYY/MM/DD HH:mm")}</Paragraph>;
      },
    },
    {
      title: "人数",
      dataIndex: "numPeople",
      render(text: string) {
        return <Paragraph>{text}人</Paragraph>;
      },
    },
    {
      title: "会計金額",
      render(_: string, { taxIncludedPaidAmount }: TableUser) {
        return formatPrice(taxIncludedPaidAmount);
      },
    },
    {
      title: "状態",
      render(_: string, { state }: TableUser) {
        return <TableUserStatus state={state} />;
      },
    },
    {
      title: "テーブルクリア理由",
      dataIndex: "tableClearReason",
    },
    {
      title: "",
      render(_: string, { state, id, shopId }: TableUser) {
        if (state === "inactive") return null;
        return (
          <Popconfirm
            title={() => (
              <>
                <Typography.Paragraph>インアクティブにしますか？</Typography.Paragraph>
                <Typography.Paragraph>
                  テーブルクリアと異なり、会計情報や注文情報はキャンセル扱いされません。
                </Typography.Paragraph>
              </>
            )}
            onConfirm={() => deactivateTableUser({ shopId, tableUserId: id })}
            okText="インアクティブにする"
            cancelText="キャンセル"
          >
            <Button type="link">インアクティブにする</Button>
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={tableUsers}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => {
        setPagination(pagination);
      }}
    />
  );
});
