import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, List, Space, Typography } from "antd";
import dayjs from "dayjs";

import { MainLayout } from "components/Layout/MainLayout";
import { TableUserStatus } from "components/TableUserStatus";
import { useSearchParams } from "hooks/useSearchParams";

import { ActivePlanTable } from "./ActivePlanTable";
import { CheckInEventTable } from "./CheckInEventTable";
import { CustomerCheckInHistoryDialog } from "./CustomerCheckInHistoryDialog";
import { OnSitePaymentTable } from "./OnSitePaymentTable";
import { OrderTable } from "./OrderTable";
import {
  useTableUserDetailGetActivePlanQuery,
  useTableUserDetailGetCheckInEventsQuery,
  useTableUserDetailGetMenuOrderItemEventsQuery,
  useTableUserDetailGetOnSitePaymentQuery,
  useTableUserDetailGetTableUserCustomerQuery,
  useTableUserDetailGetTableUserMovedEventsQuery,
  useTableUserDetailGetTableUserQuery,
} from "./queries";
import { TableUserCustomerTable } from "./TableUserCustomerTable";
import { TableUserMovedEventTable } from "./TableUserMovedEventTable";
import { TableUserTakeOutStatus } from "./TableUserTakeOutStatus";

const { Title, Paragraph } = Typography;

export const TableUserDetail = () => {
  const { id: tableUserId } = useParams<{ id: string }>();
  const { getSearchParam } = useSearchParams();

  const { data } = useTableUserDetailGetTableUserQuery(
    tableUserId
      ? {
          variables: { tableUserId },
        }
      : { skip: true },
  );

  const tableUser = data?.tableUser_by_pk;
  const childrenTableUsers = useMemo(
    () => data?.childrenTableUsers?.filter(({ id }) => id !== tableUserId) ?? [],
    [data?.childrenTableUsers, tableUserId],
  );

  const { loading: loadingMenuOrderItemEvents, data: menuOrderItemEventsData } =
    useTableUserDetailGetMenuOrderItemEventsQuery(
      tableUser ? { variables: { tableUserId: tableUser.id } } : { skip: true },
    );

  const orderEvents = useMemo(
    () =>
      [
        ...(menuOrderItemEventsData?.menuOrderItemCreatedEvent ?? []),
        ...(menuOrderItemEventsData?.menuOrderItemQuantityDecreasedEvent ?? []),
        ...(menuOrderItemEventsData?.menuOrderItemQuantityIncreasedEvent ?? []),
      ].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    [menuOrderItemEventsData],
  );

  const { data: activePlanData, loading: loadingActivePlan } = useTableUserDetailGetActivePlanQuery(
    tableUser ? { variables: { tableUserId: tableUser.id } } : { skip: true },
  );

  const activePlans = useMemo(() => activePlanData?.activePlan ?? [], [activePlanData?.activePlan]);

  const { data: tableUserMovedEventsData, loading: loadingTableUserMovedEvents } =
    useTableUserDetailGetTableUserMovedEventsQuery(
      tableUser ? { variables: { tableUserId: tableUser.id } } : { skip: true },
    );

  const tableUserMovedEvents = useMemo(
    () => tableUserMovedEventsData?.tableUserMovedEvent ?? [],
    [tableUserMovedEventsData?.tableUserMovedEvent],
  );

  const { data: checkInEventsData, loading: loadingCheckInEvents } =
    useTableUserDetailGetCheckInEventsQuery(
      tableUser ? { variables: { tableUserId: tableUser.id } } : { skip: true },
    );

  const checkInEvents = useMemo(
    () => checkInEventsData?.checkInEvent ?? [],
    [checkInEventsData?.checkInEvent],
  );

  const { data: tableUserCustomerData, loading: loadingTableUserCustomer } =
    useTableUserDetailGetTableUserCustomerQuery(
      tableUser ? { variables: { tableUserId: tableUser.id } } : { skip: true },
    );

  const tableUserCustomers = useMemo(
    () => tableUserCustomerData?.tableUserCustomer ?? [],
    [tableUserCustomerData],
  );

  const { data: onSitePaymentData, loading: loadingOnSitePayment } =
    useTableUserDetailGetOnSitePaymentQuery(
      tableUser ? { variables: { tableUserId: tableUser.id } } : { skip: true },
    );

  const onSitePayments = useMemo(
    () => onSitePaymentData?.onSitePayment ?? [],
    [onSitePaymentData?.onSitePayment],
  );

  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(null);

  const selectedCustomer = checkInEvents.find(
    (event) => event?.customer?.id === selectedCustomerId,
  )?.customer;

  const customerCheckInHistoryDialogVisible = Boolean(selectedCustomerId);

  const handleCloseCustomerCheckInHistoryDialog = useCallback(() => {
    setSelectedCustomerId(null);
  }, []);

  useEffect(() => {
    setSelectedCustomerId(null);
  }, [tableUser?.id]);

  return (
    <MainLayout title="テーブル利用詳細">
      <Space direction="vertical" size="large">
        <Card>
          <List>
            <List.Item>
              <List.Item.Meta title="テーブル名" description={tableUser?.table.name} />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="ID"
                description={
                  <Paragraph copyable={{ text: tableUser?.id }}>{tableUser?.id}</Paragraph>
                }
              />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="親のID"
                description={
                  tableUser?.id === tableUser?.billableTableUserId ? (
                    "-"
                  ) : (
                    <Paragraph copyable={{ text: tableUser?.billableTableUserId }}>
                      <Link
                        to={`/table-users/${tableUser?.billableTableUserId}?shopId=${getSearchParam(
                          "shopId",
                        )}`}
                      >
                        {tableUser?.billableTableUserId}
                      </Link>
                    </Paragraph>
                  )
                }
              />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="子のID"
                description={
                  childrenTableUsers.length === 0
                    ? "-"
                    : childrenTableUsers.map((childTableUser) => (
                        <Paragraph copyable={{ text: childTableUser.id }} key={childTableUser.id}>
                          <Link
                            to={`/table-users/${childTableUser.id}?shopId=${getSearchParam(
                              "shopId",
                            )}`}
                          >
                            {childTableUser.id}
                          </Link>
                        </Paragraph>
                      ))
                }
              />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="状態"
                description={tableUser?.state && <TableUserStatus state={tableUser?.state} />}
              />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="店内外"
                description={<TableUserTakeOutStatus isTakeOut={Boolean(tableUser?.isTakeOut)} />}
              />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="合計金額"
                description={
                  tableUser?.id === tableUser?.billableTableUserId
                    ? `${tableUser?.taxIncludedPaidAmount}円`
                    : "-"
                }
              />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="テーブル立ち上げ日時"
                description={dayjs(tableUser?.activatedAt).format("MM/DD HH:mm")}
              />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="インアクティブ日時"
                description={
                  tableUser?.deactivatedAt
                    ? dayjs(tableUser?.deactivatedAt).format("MM/DD HH:mm")
                    : "-"
                }
              />
            </List.Item>

            <List.Item>
              <List.Item.Meta
                title="テーブルクリア理由"
                description={tableUser?.tableClearReason ?? "-"}
              />
            </List.Item>
          </List>
        </Card>

        <div>
          <Title level={4}>チェックイン一覧</Title>

          <CheckInEventTable
            loading={loadingCheckInEvents}
            checkInEvents={checkInEvents}
            onSelectCustomer={setSelectedCustomerId}
          />
        </div>

        <div>
          <Title level={4}>利用顧客一覧</Title>

          <TableUserCustomerTable
            loading={loadingTableUserCustomer}
            tableUserCustomers={tableUserCustomers}
          />
        </div>

        <div>
          <Title level={4}>注文履歴</Title>

          <OrderTable loading={loadingMenuOrderItemEvents} events={orderEvents} />
        </div>

        <div>
          <Title level={4}>プラン</Title>

          <ActivePlanTable loading={loadingActivePlan} activePlans={activePlans} />
        </div>

        <div>
          <Title level={4}>卓移動履歴</Title>

          <TableUserMovedEventTable
            loading={loadingTableUserMovedEvents}
            tableUserMovedEvents={tableUserMovedEvents}
          />
        </div>

        <div>
          <Title level={4}>会計履歴</Title>

          <OnSitePaymentTable loading={loadingOnSitePayment} onSitePayments={onSitePayments} />
        </div>
      </Space>

      <CustomerCheckInHistoryDialog
        customer={selectedCustomer}
        isOpen={customerCheckInHistoryDialogVisible}
        onClose={handleCloseCustomerCheckInHistoryDialog}
      />
    </MainLayout>
  );
};
